import FetchActions from '@store/actions/fetchActions'

/** State 接口 */
export interface FetchState {
  pageLoading: boolean
}

/** 初始狀態 */
export const INITIAL_FETCH_STATE: FetchState = {
  pageLoading: false
}


/** 默認 reducer */
export default function fetchReducer(state: FetchState = INITIAL_FETCH_STATE, action: FetchActions ) {
  switch (action.type) {
    case 'fetch_begin': {
      return {
        ...state,
        pageLoading: action.payload.pageLoading
      }
    }

    case 'fetch_success': {
      return {
        ...state,
        pageLoading: action.payload.pageLoading
      }
    }

    case 'fetch_failed': {
      return {
        ...state,
        pageLoading: action.payload.pageLoading
      }
    }

    default:
      return state
  }
}
